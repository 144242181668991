<template>
  <div>
    <v-container class="fill-heigt pt-3" fluid>
      <v-container class="fill-heigt pt-0" fluid>
        <v-row class="">
          <v-col cols="12" md="8" class="text-left px-3">
            <v-row>
              <div class="text-left black--text pt-6">
                <v-btn
                  color="primary"
                  :style="
                    listVacancies ? 'background: #CCEBE9' : 'background: #fff'
                  "
                  text
                  small
                  class="text-capitalize"
                  @click="listVacancies = !listVacancies"
                >
                  <span class="body-2 black--text font-weight-medium">{{
                    $t("Job_Openings", locale)
                  }}</span>
                </v-btn>
              </div>
              <div class="text-left black--text px-2 pt-6">
                <v-btn
                  color="primary"
                  @click="listVacancies = !listVacancies"
                  :style="
                    !listVacancies ? 'background: #CCEBE9' : 'background: #fff'
                  "
                  text
                  small
                  class="text-capitalize"
                >
                  <span class="body-2 black--text">{{
                    $t("spontaneou_application", locale)
                  }}</span>
                </v-btn>
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" md="4" class="text-right px-0 mt-2">
            <v-row>
              <div class="px-0 pt-3"></div>
              <v-text-field
                class="px-2"
                v-model="textSearch"
                append-icon="mdi-magnify"
                :label="$t('cand_search_position', locale)"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-row>
          </v-col> </v-row
      ></v-container>

      <v-divider class="my-1 mb-3"></v-divider>
      <v-row v-if="listVacancies">
        <v-col
          cols="12"
          md="3"
          lg="3"
          xl="4"
          v-for="vacancy in filteredItems"
          :key="vacancy.id"
        >
          <v-skeleton-loader
            v-if="$apollo.loading"
            elevation="2"
            v-bind="filteredItems"
            type="image, article"
          ></v-skeleton-loader>
          <vacancy-card
            v-else
            :vacancy="vacancy"
            :apply_cand="false"
            :done="true"
            @view="view(vacancy)"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="3"> </v-col>
        <v-col cols="12" md="6">
          <br /><br />
          <v-card
            class="mx-auto mt-12 my-6"
            max-height="220"
            color="#fbf9f9"
            elevation="0"
          >
            <v-card-text>
              <v-row justify="space-around" class="pa-2 mx-auto">
                <v-col cols="12" md="12">
                  <p class="text-center black--text">
                    {{ $t("expo_candidate_text1", locale) }}
                  </p>
                  <p class="text-center black--text" style="margin-top: -3.4%">
                    {{ $t("expo_candidate_text2", locale) }}
                  </p>
                </v-col>
                <v-col cols="12" md="12">
                  <v-row justify="center">
                    <div v-if="!isLoading">
                      <v-btn color="primary" class="" @click="openDialog(null)">
                        <v-icon small>mdi-send</v-icon>
                        <span class="white--text">{{ $t("job", locale) }}</span>
                      </v-btn>

                      <v-btn
                        color="primary"
                        text
                        class="mx-3"
                        style="background: #CCEBE9"
                        @click="openDialog(null)"
                      >
                        <v-icon small>mdi-send</v-icon>
                        <span class="primary--text">{{
                          $t("Internship", locale)
                        }}</span>
                      </v-btn>
                    </div>
                    <!--  -->
                    <v-btn block loading text v-else></v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <apply-vacancy />
    </v-container>
  </div>
</template>
<script>
import { SHARED_VACANCIES } from "../graphql/Query.gql";
import VacancyCard from "./../../candidate/components/VacancyCard.vue";
import { statusVacancyMixins } from "@/mixins/status";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import ApplyVacancy from "./../modules/vacancy/components/ApplyVacancy.vue";
export default {
  components: { VacancyCard, ApplyVacancy },
  mixins: [statusVacancyMixins, replaceSpecialCharsMixins],
  name: "vacancies",
  data: () => ({
    vacancies: [],
    entity_id: [],
    textSearch: undefined,
    locale: "pt",
    listVacancies: true,
  }),
  apollo: {
    getSharedVacacny: {
      query: SHARED_VACANCIES,
      variables() {
        return {
          id: this.$route.params.id ? this.$route.params.id : 0,
        };
      },
      fetchPolicy: "no-cache",
      result({ data }) {
        // let index = 0;
        // let ids = this.$route.params.vacancies.split(",");
        this.vacancies = data.getSharedVacacny
      },
    },
  },
  computed: {
    filteredItems() {
      let resultItems = [...this.vacancies];
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }
      resultItems = resultItems.filter((vacancy) => {
        console.log("this.status: ", this.status2(vacancy).status );

        return (
          this.status(vacancy).text !== "Waiting" &&
          this.status(vacancy).text !== "Em espera"
          
        );
      });
      resultItems = resultItems.filter((v) => v.entity != null && (this.status2(v).status !== "Expirado" || this.status2(v).status !=="expired"));
      return resultItems;
    },
  },
  created() {
    this.locale = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "pt";

    this.loginParams = "true"
    localStorage.setItem("loginParams", this.loginParams);
  },
  methods: {
    view(vacancy) {
      // vacancy/:id
      this.$router.push({
        path: `/vacancy/${vacancy.id}`,
      });
    },
    openDialog(vacancy) {
      // eslint-disable-next-line no-undef
      Fire.$emit("openGuestApplyModal", vacancy);
    },
    
  },
};
</script>